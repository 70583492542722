// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
// require('turbolinks').start()
require('@rails/activestorage').start()
// require('channels')

import 'bootstrap'
import $ from 'jquery'

$(() => {
  /* ======= TOGGLE SIDE MENU ======= */
  $('li.side-menu a').on('click', function(e) {
    e.preventDefault()
    $('.side').toggleClass('on')
    $('body').toggleClass('on-side')
  })

  if ($('.side .close-side').length) {
    $('.side .close-side').on('click', function(e) {
      e.preventDefault()
      $('.side').removeClass('on')
      $('body').removeClass('on-side')
    })
  }

  $('.menu-on, .menu-off').on('click', function(e) {
    e.preventDefault()
    $('#menu-mobile').toggleClass('hidden')
    $('.menu-on').toggleClass('hidden')
    $('.menu-off').toggleClass('hidden')
    $('body').toggleClass('on-side')
  })



  /* ======= OFF CANVAS MENU OVERLAY ======= */
  if ($('li.side-menu a').length) {
    $('li.side-menu a').on('click', function() {
      $('.body-overlay').addClass('active')
    })
  }

  if ($('.body-overlay').length) {
    $('.body-overlay').on('click', function() {
      $(this).removeClass('active')
      $('.side').removeClass('on')
      $('body').removeClass('on-side')
    })
  }

  if ($('a.scroll-top').length) {
    $('.side .close-side').on('click', function() {
      $('.body-overlay').removeClass('active')
    })
  }

  /* ======= PAGE SCROLLING ======= */
  if ($('a.scroll-top').length) {
    $('a.scroll-top').on('click', function(e) {
      e.preventDefault()
      $('html, body').animate({ scrollTop: 0 }, 800)
      return false
    })
  }

  $('.multi-item-carousel .item').each(function () {
    var next = $(this).next()

    if (!next.length) {
      next = $(this).siblings(':first')
    }
    next.children(':first-child').clone().attr("aria-hidden", "true").appendTo($(this))

    if (next.next().length > 0) {
      next.next().children(':first-child').clone().attr("aria-hidden", "true").appendTo($(this))
    }
    else {
      $(this).siblings(':first').children(':first-child').clone().appendTo($(this))
    }
  })

  // Instantiate the Bootstrap carousel
  $('.multi-item-carousel').carousel({
    interval: false
  })

  // Modal
  if ($('#modal-window').length) {
    $('.close').on('click', function (e) {
      e.preventDefault()
      $('#modal-window').removeClass('show')
    })
  }
})
